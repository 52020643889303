import request from '@/util/request'
/* service 页面轮播图文章数据 */
export const APIservice = (data) => {
    return request({
        url: '/index/service',
        method: 'post',
        data
    })
}

/* us 页面接口数据 轮播图数据 */
export const APIus = (data) => {
    return request({
        url: '/index/us',
        method: 'post',
        data
    })
}

/*用户表单提交*/
export const APIpropose = (data) => {
    return request({
        url: '/index/propose',
        method: 'post',
        data
    })
}

/*首页分类商品*/
export const APIclassif = (data) => {
    return request({
        url: '/index/classifyGoodsList',
        method: 'post',
        data
    })
}

/*首页某个商品折线图数据*/
export const APIlineChart = (data) => {
    return request({
        url: '/index/lineChart',
        method: 'post',
        data
    })
}

//搜索商品接口
export const APIgoodsSearch = (data) => {
    return request({
        url: '/index/goodsSearch',
        method: 'post',
        data
    })
}

//首页向上翻滚商品数据
export const APIgoodsInfo = (data) => {
    return request({
        url: '/index/goodsInfo',
        method: 'post',
        data
    })
}

//首页工厂
export const APIfactoryList = (data) => {
    return request({
        url: '/index/factoryList',
        method: 'post',
        data
    })
}

//头-地公共数据
export const APIconfigInfo = (data) => {
    return request({
        url: '/common/configInfo',
        method: 'post',
        data
    })
}

// 获取新闻列表
export const getNewsList = (data) => {
    return request({
        url: '/index/newslist',
        method: 'post',
        data
    })
}

// 获取新闻详情
export const getNewsInfo = (data) => {
    return request({
        url: '/index/newsinfo',
        method: 'post',
        data
    })
}