<template>
  <div>
    <com-header></com-header>
    <router-view></router-view>
    <com-footer></com-footer>
  </div>
</template>

<script>
import ComHeader from "@/components/ComHeader.vue";
import ComFooter from '@/components/ComFooter.vue';
export default {
  components: { ComHeader, ComFooter },
};
</script>

<style l
ComHeaderang="scss" scoped>
</style>
