
 //日期转字符串格式
 export function DateToStr(time) {
    let date=new Date(Number(time))
    var year = date.getFullYear();//年
    var month = date.getMonth();//月
    var day = date.getDate();//日
    return year + "-" +
        ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
        (day > 9 ? day : ("0" + day))
 }

 