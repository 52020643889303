<template>
  <div class="com-footer">
    <div class="foottop">
      <div>
        <div class="footnav">
          <router-link to="/">Home</router-link><span>|</span>
          <router-link to="/frontend/user">My Account</router-link><span>|</span>
          <router-link to="/frontend/goods/factoryList">Suppliers</router-link><span>|</span>
          <router-link to="/frontend/goods/products">Key Products</router-link><span>|</span>
          <router-link to="/frontend/news">News</router-link><span>|</span>
          <router-link to="/frontend/about/service">Our Services</router-link><span>|</span>
          <router-link to="/frontend/about/us">About Us</router-link>
        </div>
        <div class="footlcp">
          <span>{{ dibu.bottom_hint }}</span>
          <span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:#8ebff4">{{ dibu.eori
          }}</a></span>
          <span><a href="javascript:;">公安部备案</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APIconfigInfo } from "@/api/home.js";
export default {
  created() {
    this.configInfo()
  },
  data() {
    return {
      dibu: {}
    }
  },
  methods: {
    //底部数字
    async configInfo() {
      let res = await APIconfigInfo()
      if (res.status === 200) {
        this.dibu = res.data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.foottop {
  margin-top: 40px;
  background-color: #052535;
  background-image: url("@/../public/image/GettyImages.508066117.high_res_comp.png");
  color: #fff;

  >div {
    width: 60%;
    margin: 0 auto;
    padding-bottom: 50px;
  }

  .footnav {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    // border-bottom: 1px solid #fff;
    font-size: 18px;
    // ::after{
    //   content: "";
    //   display: block;
    //   height: 100px;
    //   width: 10px;
    //   background: #fff;
    // }
  }

  .footlcp {
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    line-height: 50px;

    >span:first-child {
      margin-right: 10px;
    }
  }
}

// @media screen and (max-width:1024px) {
//   .foottop .footnav{
//     font-size: 14px;
//   }
// }
@media screen and (max-width:820px) {
  .foottop .footnav {
    font-size: 12px;
  }
}

@media screen and (max-width:768px) {
  .foottop .footlcp>span:first-child {
    margin-right: 30px;
  }
}

@media screen and (max-width:414px) {
  .foottop .footlcp {
    font-size: 13px;

    >span {
      display: block;
    }
  }

  .foottop .footlcp>span:first-child {
    margin-right: 0;
  }

  .foottop>div {
    width: 80%;
  }

  .foottop .footnav {
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 30px 10px;
  }

  .foottop .footnav a {
    padding: 0 5px;
  }
}
</style>
