<template>
  <div id="app">
    <router-view v-if="key"/>
  </div>
</template>
<script>
export default {
  provide(){
    return {
      reload:this.reload
    }
  },
  data() {
    return {
      key:false
    }
  },
  created(){
    sessionStorage.removeItem('fend')
    this.key=true
  },
  methods:{
    //刷新当前页
    reload(){
      this.key=false
      this.$nextTick(()=>{
        this.key=true
      })
    }
  }
}
</script>
<style lang="scss">
@charset "UTF-8";

html {
  font-family: "Microsoft YaHei", Arial !important;
}

html,
body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

body {
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  outline: 0;
}

html,
body,
header,
section,
footer,
div,
ul,
ol,
li,
img,
a,
span,
em,
del,
legend,
center,
strong,
var,
fieldset,
form,
label,
dl,
dt,
dd,
cite,
input,
hr,
time,
mark,
code,
figcaption,
figure,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  border: 0;
  padding: 0;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  user-select: none;
}

.contarner {
  width: 75%;
  margin: 0 auto;
}

.swiper-slide img {
  width: 100% !important;
}

@media screen and (max-width: 390px) {
  .contarner {
    width: 85%;
  }
}
</style>
