import Vue from "vue";
import ElementUI, { Main } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./util/pxtorem";
import "@/text/text.scss";
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.prototype.$axios = axios
Vue.use(VueAxios, axios) // 安装插件
// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
// import './mock'
Vue.use(ElementUI);
Vue.config.productionTip = false;
// filter
import * as filters from '@/filter/filter.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");