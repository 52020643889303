import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/Index.vue";
import front from "@/views/Frontend.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    children: [
      {
        path: "/",
        name: "main",
        component: () => import("../views/main/IndexMain.vue"),
      },
    ],
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search/search.vue"),
  },
  {
    path: "/frontend",
    name: "frontend",
    component: front,
    children: [
      {
        path: "user",
        name: "user",
        component: () => import("../views/account/MyAccount.vue"),
      },
      {
        path: "goods/factoryList",
        name: "factoryList",
        component: () => import("../views/goods/factorylist.vue"),
      },
      {
        path: "goods/factoryDetail",
        name: "factoryDetail",
        component: () => import("../views/goods/factorydetail.vue"),
      },
      {
        path: "goods/products",
        name: "products",
        component: () => import("../views/goods/products.vue"),
      },
      {
        path: "goods/products/detail",
        name: "detail",
        component: () => import("../views/goods/detail.vue"),
      },
      {
        path: "about/service",
        name: "service",
        component: () => import("../views/about/service.vue"),
      },
      {
        path: "about/us",
        name: "us",
        component: () => import("../views/about/us.vue"),
      },
      {
        path: "news",
        name: "news",
        component: () => import("../views/news/index.vue"),
      },
      {
        path: "news/detail",
        name: "newsdetail",
        component: () => import("../views/news/detail.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/login/password.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

//路由导航守卫
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  if (
    to.path === "/frontend/user" ||
    to.path === "/frontend/goods/factoryList" ||
    to.path === "/frontend/goods/factoryDetail" ||
    to.path.includes("/frontend/goods/products")
  ) {
    if (token) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
