import request from '@/util/request'
/* 分类列表 */
export const APIclassifyList = (data) => {
    return request({
        url: '/Goods/classifyList',
        method: 'post',
        data
    })
}

// 获取商品列表数据
export const APIgoodsList = (data) => {
    return request({
        url: '/Goods/goodsList',
        method: 'post',
        data
    })
}

// 详情
export const APIgoodsInfo = (data) => {
    return request({
        url: '/Goods/goodsInfo',
        method: 'post',
        data
    })
}