<template>
  <div class="com-header" :class="{
    bgi: $route.path === '/',
    bgc: $route.path !== '/',
  }">
    <div class="onehead contarner">
      <h3 class="loge"><router-link to="/">Ulikechem.com</router-link></h3>
      <div class="inp">
        <input type="search" v-model="sousuo" placeholder="Please Enter Cas No. or Product Name" />
        <div class="div" v-show="$route.path === '/'" @click="Sousuo(sousuo, type)">
          <img src="@/../public/image/Search_b64px.png" alt="" />
        </div>
        <div class="div" v-show="$route.path !== '/'" @click="Sousuo(sousuo, type)">
          <img src="@/../public/image/Search_w64px.png" alt="" />
        </div>
      </div>
      <div>
        <span @click.stop="addpush('/login')" v-show="!token">Sign in</span>
        <span v-show="!token">|</span>
        <span @click.stop="addpush('/register')" v-show="!token">Join Free</span>
        <span v-if="token" class="signout">
          <span class="text">Welcome ! {{ name }}</span>
          <span class="butt" @click="Account()">Sign Out</span>
        </span>
      </div>
    </div>
    <div class="twohead contarner">
      <div @mouseover="addhoverdiv" @mouseout="removehoverdiv">
        <span>All Products</span><span class="el-icon-caret-bottom"></span>
        <div class="hoverdiv" v-show="hoverdivv">
          <span></span>
          <div class="hoverdiv-left">
            <div :class="qie === i ? 'hoveact' : ''" v-for="(v, i) in fenl" :key="i + 'hove'" @mouseover="(qie = i)">
              <span>{{ v.name }}</span><span class="el-icon-arrow-right"></span>
            </div>
          </div>
          <div class="hoverdiv-right">
            <div>
              <router-link :to="{ name: 'products', params: title }">more</router-link>
            </div>
            <ul>
              <li @click="removehoverdiv" v-show="qie === i" v-for="(v, i) in fenl" :key="i + 'uili'">
                <router-link v-for="(v1, i1) in v.list" :key="i1 + 'link'"
                  :to="`/frontend/goods/products/detail?id=${v1.id}&name=${v1.product_name}`">{{ v1.product_name
                  }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <span v-show="spannone">
        <router-link to="/" :class="{ pathact: $route.path == '/' }">Home</router-link>
        <router-link to="/frontend/user" :class="{ pathact: $route.path == '/frontend/user' }">My Account</router-link>
        <router-link to="/frontend/goods/factoryList" :class="{
    pathact: $route.path.substr(0, 17) == '/frontend/goods/f',
  }">Suppliers</router-link>
        <router-link to="/frontend/goods/products" :class="{
    pathact: $route.path.substr(0, 17) == '/frontend/goods/p',
  }">Key Products</router-link>
        <router-link :class="{
    pathact: $route.path.substr(0, 17) == '/frontend/news',
  }" to="/frontend/news">News</router-link>
        <router-link to="/frontend/about/service" :class="{
    pathact: $route.path.substr(0, 17) == '/frontend/about/s',
  }">Our Services</router-link>
        <router-link to="/frontend/about/us" :class="{
    pathact: $route.path.substr(0, 17) == '/frontend/about/u',
  }">About Us</router-link>
      </span>
      <b ref="b" @click="spannone = spannone ? false : true"></b>
    </div>
    <template v-if="$router.currentRoute.path == '/'">
      <div class="threehead contarner">
        <router-link to="/frontend/about/us">Our vision</router-link>
        <router-link to="/frontend/about/us">Depending on information and technology</router-link>
        <router-link to="/frontend/about/us">Support our clients for their greatest success</router-link>
        <router-link to="/frontend/about/us">More About Us</router-link>
      </div>
    </template>
  </div>
</template>

<script>
import { APIclassifyList, APIgoodsList } from '@/api/products.js'
export default {
  inject: ['reload'],
  data() {
    return {
      hoverdivv: false,
      spannone: true,
      title: 'Hot Products',
      //搜索input
      sousuo: '',
      //分类
      qie: 0,
      fenl: [],
      //token
      token: sessionStorage.getItem('token') || '',
      name: sessionStorage.getItem('name') || ''
    };
  },
  created() {
    this.classifyList()
  },
  mounted() {
    if (document.documentElement.clientWidth < 400) {
      this.spannone = false;
      document.addEventListener("click", (e) => {
        e.stopPropagation();
        let that = this;
        if (e.target != this.$refs.b) {
          that.spannone = false; //点击其他区域关闭
        } else {
          that.spannone = true;
        }
      });
    }
    this.spans();
  },
  methods: {
    spans() {
      if (window.innerWidth < 920) {
        this.spannone = false;
      }
    },
    addpush(e) {
      this.$router.push(e);
    },
    addhoverdiv() {
      this.hoverdivv = true;
      this.hovermask();
    },
    removehoverdiv() {
      this.hoverdivv = false;
      this.hovermask();
    },
    hovermask() {
      this.$emit("mask", this.hoverdivv);
    },
    //搜索
    Sousuo(name) {
      if (!name) { return }
      this.$router.push('/search?&name=' + name)
    },
    //分类列表数据
    async ddac(id) {
      let res = await APIgoodsList({ category: id })
      if (res.status === 200) {
        return res.data
      } else {
        return 0
      }
    },
    //获取分类列表
    async classifyList() {
      let fen = JSON.parse(sessionStorage.getItem('fend')) || ''
      if (fen) {
        this.fenl = fen
        return;
      }
      let pin = true;
      let res = await APIclassifyList()
      if (res.data.code === 0) {
        let arr = res.data.data
        for (let i = 0; i < arr.length; i++) {
          let red = await this.ddac(arr[i].id)
          this.$set(arr[i], 'list', red)
          if (red === 0) {
            pin = false;
          }
        }
        this.fenl = arr
        if (pin) {
          sessionStorage.setItem('fend', JSON.stringify(arr))
        }
      }
    },
    // 退出
    Account() {
      sessionStorage.removeItem('token')
      this.reload()
    }
  },
};
</script>

<style lang="scss" scoped>
.bgi {
  background-image: url("../../public/image/624532079929475318.png");
  background-position-y: 100%;
  background-size: cover;
  height: 622px;
  color: #fff;
  padding: 73px 0;

  a {
    color: #fff;
  }

  .onehead {
    .loge {
      a {
        font-style: italic !important;
      }
    }

    a {
      color: #fff;
    }

    .inp {
      border: 2px solid #fff;
      position: relative;

      .select {
        ::v-deep .el-input__inner {
          color: #1e1e1e;
        }
      }

      >input {
        color: #fff;
      }

      &>.div {
        background: #ffffff;
        color: #333;
      }
    }

    >div:nth-child(3) {
      width: 100%;
      background: #4066d3;
    }
  }

  .twohead {
    margin-bottom: 86px;

    >div {
      background: #4066d3;
    }

    b {
      background-image: url("@/../public/image/openi.png");
    }
  }

  .threehead {
    padding-left: 20px;
  }
}

.bgc {
  background-color: #fff;
  padding-top: 73px;
  padding-bottom: 22px;

  a {
    color: #052535;
  }

  .onehead {
    .loge {
      a {
        font-style: italic !important;
      }
    }

    a {
      color: #052535;
    }

    .inp {
      border: 2px solid #052535;

      >input {
        color: #052535;
      }

      >div {
        background-color: #052535;
        color: #fff;
      }
    }

    >div:nth-child(3) {
      background: #052535;
    }
  }

  .twohead {
    border-bottom: 1px solid #d8d8d8;

    >div {
      background: transparent;
    }

    b {
      background-image: url("@/../public/image/open.png");
    }
  }

  .threehead {
    padding-left: 20px;
  }
}

.com-header {

  // background-image: url("../../public/image/624532079929475318.png");
  // background-position-y: 100%;
  .pathact {
    border-bottom: 1px solid #4066d3;
    color: #4066d3;
  }

  a {
    text-decoration: none;
  }

  .onehead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    a {
      font-size: 40px;
      font-weight: 1000;
    }

    .inp {
      width: 598px;
      height: 44px;
      border-radius: 22px;
      padding: 1px;
      position: relative;

      ::v-deep .el-select {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 101% !important;
        background-color: transparent !important;
        width: min-content;

        .el-input__inner {
          border: 0;
          background-color: #ffffff;
          width: 100px;
          min-width: 72PX;
        }
      }

      >input {
        width: 100%;
        background-color: transparent;
        padding: 0 20px;
        height: 100%;
      }

      >.div {
        width: 77px;
        height: 44px;
        border-radius: 0px 22px 22px 0px;
        text-align: center;
        line-height: 44px;
        position: absolute;
        right: -2px;
        top: -2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      >div:hover {
        background-color: #3f63eb;
      }
    }

    >div:nth-child(3) {
      width: 280px;
      // height: 43px;
      border-radius: 6px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 10px 15px;
    }

    .signout {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .text {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .butt {
        user-select: none;
        cursor: pointer;
        background-color: #3f63eb;
        padding: 0 6px;
        border-radius: 2px;
      }
    }
  }

  .twohead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 29px;
    padding-right: 61px;
    position: relative;

    >div {
      width: 18%;
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      font-weight: bold;
    }

    >span {
      width: calc(100% - 18%);
      display: flex;
      justify-content: flex-end;
      gap: 65px;
      align-items: center;

      a {
        // height: 38px;
        // line-height: 38px;
        padding: 10px 10px;
      }

      a:hover {
        color: #4066d3;
      }

      a:active {
        border-bottom: 1px solid #4066d3;
      }
    }

    >b {
      display: none;
      width: 40px;
      height: 40px;
      background-size: 100%;
    }
  }

  .threehead {
    display: flex;
    flex-direction: column;

    >a:nth-child(1) {
      font-size: 60px;
      font-weight: 900;
      color: #ffffff;
      line-height: 1;
      margin-bottom: 48px;
    }

    >a:nth-child(2),
    a:nth-child(3) {
      font-size: 24px;
      color: #c9c9c9;
    }

    >a:nth-child(3) {
      margin-top: 9px;
      color: #4066d3;
    }

    >a:nth-child(4) {
      color: #26447b;
      width: 193px;
      border: 2px solid #26447b;
      margin-top: 29px;
      text-align: center;
      padding: 10px 0;
    }
  }

  .hoverdiv {
    font-size: 14px;
    position: absolute;
    z-index: 10000;
    width: 720px;
    // height: 280px;
    background-color: #f5f5f5;
    color: #4c4c4c;
    padding: 15px 0;
    top: 40px;
    left: 0;
    display: flex;

    >span {
      // display: block;
      position: absolute;
      // color: red;
      top: -20px;
      left: 105px;
      border-bottom: 10px solid #f5f5f5;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    .hoverdiv-left {
      flex: none;
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: 0 20px;
      border-right: 1px solid #e6e6e6;

      >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #e6e6e6;

        >span:nth-child(2) {
          color: #ccc;
        }
      }

      >div:last-child {
        border: 0;
      }

      >.hoveact {
        color: #4066d3;

        >.el-icon-arrow-right {
          color: #333;
        }
      }
    }

    .hoverdiv-right {
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      div {
        text-align: right;
        border-bottom: 1px solid #d8d8d8;

        a {
          color: #1e1e1e;
          font-size: 16px;
        }

        a::after {
          content: ">";
          display: inline-block;
          margin-left: 10px;
        }

        a:hover {
          color: #4066d3;
        }
      }

      ul {
        height: 100%;
        padding-top: 20px;
      }

      li {
        width: 100%;
        list-style: none;
        font-size: 14px;
        line-height: 30px;
        height: 100%;
        max-height: 200px;
        overflow-y: auto;

        a {
          display: block;
          color: #7c7c7c;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a:hover {
          color: #4066d3;
        }
      }
    }
  }
}

// @media screen and (max-width: 1600px) {
//   .com-header .onehead a {
//     font-size: 35px;
//   }
//   .com-header .onehead .inp {
//     width: 498px;
//   }
//   .com-header .onehead > div:nth-child(3) {
//     width: 183px;
//   }
// }
// @media screen and (max-width: 1280px) {
//   .com-header .onehead a {
//     font-size: 30px;
//   }
//   .com-header .onehead > div:nth-child(3) {
//     width: 160px;
//   }
// }
// @media screen and (max-width: 1024px) {
//   .com-header .onehead a {
//     font-size: 30px;
//   }
//   .com-header .onehead > div:nth-child(3) {
//     width: 140px;
//   }
//   .com-header .onehead .inp {
//     width: 300px;
//   }
//   .com-header .twohead > div {
//     width: 180px;
//   }
//   .com-header .twohead {
//     padding-right: 20px;
//   }
//   .com-header .threehead > a:nth-child(1) {
//     font-size: 40px;
//   }
//   .com-header .threehead > a:nth-child(2),
//   .com-header .threehead a:nth-child(3) {
//     font-size: 20px;
//   }
// }
@media screen and (max-width: 912px) {
  .bgi {
    background-position-x: 40%;
    height: 530px;
  }

  .com-header .onehead .inp>div {
    width: 60px;
  }

  .com-header .twohead>a {
    padding: 10px 4px;
  }

  .com-header .twohead {
    >b {
      display: block;
    }

    >span {
      flex-direction: column;
      position: absolute;
      z-index: 100;
      width: 100px;
      right: 20px;
      top: 50px;
    }
  }

  .bgi {
    .twohead {
      >span {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }

  .bgi .twohead {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 820px) {
  .com-header .onehead h3 {
    a {
      font-size: 20px;
    }

    // display: none;
  }

  .com-header .onehead>div:nth-child(3) {
    padding: 10px 5px;
  }

  .com-header .twohead {
    padding-right: 0;

    >span {
      right: 0;
    }
  }
}

@media screen and (max-width: 540px) {
  .com-header .onehead h3 {
    display: none;
  }

  .com-header .onehead .inp {
    width: 60%;
  }

  .com-header .onehead>div:nth-child(3) {
    width: 35%;
  }

  .com-header .hoverdiv {
    width: 400px;

    .hoverdiv-left {
      width: 40%;
    }

    .hoverdiv-right {
      width: 60%;
    }
  }

  .com-header .hoverdiv .hoverdiv-right li {
    font-size: 12px;
  }

  .com-header .hoverdiv .hoverdiv-right div {
    line-height: 2;
  }
}

@media screen and (max-width: 400px) {
  .com-header .threehead>a:nth-child(1) {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .com-header .onehead .inp {
    height: 40px;
  }

  .com-header .onehead .inp>div {
    width: 40px;
    height: 100%;
  }

  .bgi {
    padding: 40px 0;
    height: 420px;
  }

  .com-header .hoverdiv .hoverdiv-right li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 15px;
  }

  .com-header .hoverdiv {
    width: 315px;
    top: 35px;
  }

  .com-header .hoverdiv .hoverdiv-left {
    padding: 10px;
  }

  .com-header .hoverdiv .hoverdiv-left>div {
    line-height: 25px;
  }

  .bgi .twohead {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .bgi .twohead>div {
    width: 40%;
    height: 30px;
  }

  .com-header .threehead>a:nth-child(2),
  .com-header .threehead a:nth-child(3) {
    font-size: 15px;
  }

  .com-header .twohead>span {
    top: 40px;
  }

  .com-header .hoverdiv>span {
    border-width: 5px;
    top: -3.25rem;
  }

  .com-header .threehead>a:nth-child(4) {
    width: 120px;
    padding: 10px;
  }
}
</style>
