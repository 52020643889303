import axios from "axios";

//默认请求地址 千万别出现空格！！！
axios.defaults.baseURL = "https://admin.ulikechem.com/index";

//默认超时时间
axios.defaults.timeout = 10000;

//请求拦截器 作用：携带统一参数
axios.interceptors.request.use(
  (config) => {
    if (!config.url.includes("https")) {
      const uid = sessionStorage.getItem("token") || "";
      config.data = { ...config.data, uid };
    }
    return config;
  },
  (err) => {
    //如果请求错了 就抛出错误信息
    //err 错误信息
    return Promise.reject(err);
  }
);

//响应拦截器 作用：统一处理状态码（正确 & 错误）
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    //如果响应错了 就抛出错误信息
    return Promise.reject(err);
  }
);

//导出配置好的axios工具函数
export default axios;
