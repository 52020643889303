<template>
  <div>
    <com-header @mask="maskitem"></com-header>
    <router-view class="minhet"></router-view>
    <com-footer></com-footer>
    <div :class="{ bif: bi }" :style="contentStyleObj"></div>
  </div>
</template>

<script>
import ComHeader from "@/components/ComHeader.vue";
import ComFooter from "@/components/ComFooter.vue";
export default {
  components: { ComHeader, ComFooter },
  data() {
    return {
      bi: false,
      contentStyleObj: {
        height: "",
        position: "absolute",
      },
    };
  },
  watch: {
    bi(newValue, oldValue) {
      this.getHight();
    },
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  methods: {
    getHight() {
      this.contentStyleObj.height = document.body.scrollHeight - 228 + "px";
    },
    maskitem(e) {
      this.bi = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.bif {
  // position: absolute;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  top: 228px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}
.minhet{
  min-height:800px;
}
</style>
